import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Badge } from 'react-bootstrap';
import { Calendar, Users, Star, ChevronRight } from 'lucide-react';
import './PreviousBooking.css'

const PreviousBookingsPage = () => {
  const [bookings, setBookings] = useState([
    {
      id: 'BOOK123456',
      hotelName: 'Luxury Resort & Spa',
      checkIn: '2023-09-15',
      checkOut: '2023-09-17',
      guests: 2,
      roomType: 'Deluxe Double',
      totalAmount: 2912,
      status: 'Completed',
      reviewed: false,
    },
    {
      id: 'BOOK789012',
      hotelName: 'City Center Hotel',
      checkIn: '2023-08-01',
      checkOut: '2023-08-03',
      guests: 1,
      roomType: 'Standard Single',
      totalAmount: 1500,
      status: 'Completed',
      reviewed: true,
    },
    {
      id: 'BOOK345678',
      hotelName: 'Beachfront Paradise',
      checkIn: '2023-07-10',
      checkOut: '2023-07-15',
      guests: 4,
      roomType: 'Family Suite',
      totalAmount: 5000,
      status: 'Completed',
      reviewed: false,
    },
  ]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <div className="previous-bookings-page">
      <section className="section-sub-banner bg-9">
        <div className="awe-overlay"></div>
        <div className="sub-banner">
          <div className="container">
            <div className="text text-center">
              <h2>Your Previous Bookings</h2>
            </div>
          </div>
        </div>
      </section>
      <Container style={{margin: "20px auto"}}>

        {bookings.map((booking) => (
          <Card key={booking.id} className="mb-4 shadow-sm">
            <Card.Body>
              <Row>
                <Col md={8}>
                  <div className="d-flex flex-wrap gap-4 mb-3">
                    <div className="d-flex align-items-center">
                      <Calendar className="text-primary me-2" size={20} />
                      <div>
                        <small className="text-muted d-block">Check-in</small>
                        <strong>{formatDate(booking.checkIn)}</strong>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <Calendar className="text-primary me-2" size={20} />
                      <div>
                        <small className="text-muted d-block">Check-out</small>
                        <strong>{formatDate(booking.checkOut)}</strong>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <Users className="text-primary me-2" size={20} />
                      <div>
                        <small className="text-muted d-block">Guests</small>
                        <strong>{booking.guests}</strong>
                      </div>
                    </div>
                  </div>
                  <p className="mb-0"><strong>Room Type:</strong> {booking.roomType}</p>
                  <p><strong>Total Amount:</strong> ₹{booking.totalAmount}</p>
                </Col>
                <Col md={4} className="d-flex flex-column justify-content-between align-items-end">
                  <Badge bg="success" className="mb-3">{booking.status}</Badge>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}

      </Container>
    </div >
  );
};

export default PreviousBookingsPage;