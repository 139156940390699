import React from 'react';
import img1 from '../../assets/images/room/img-1.jpg';
import img2 from '../../assets/images/room/img-2.jpg';
import img3 from '../../assets/images/room/img-3.jpg';
import img4 from '../../assets/images/room/img-4.jpg';
import img5 from '../../assets/images/room/img-5.jpg';
import img6 from '../../assets/images/room/img-6.jpg';
import { Link } from 'react-router-dom';

const roomData = [
  { id: 1, name: 'AC Room', price: 1200, image: img1 },
  { id: 2, name: 'Non AC Room', price: 1500, image: img2 },
];

const RoomItem = ({ name, price, image }) => (
  <div className="col-md-6 mb-4">
    <div className="card">
      <Link to="/room">
        <img src={image} className="card-img-top" alt={name} />
        <div className="card-img-overlay d-flex flex-column justify-content-end">
          <h5 className="card-title text-white">{name}</h5>
        </div>
      </Link>
    </div>
  </div>
);

const RoomsAndRates = () => {
  return (
    <section className="py-5 bg-light">
      <div className="container">
        <h2 className="text-center mb-3"><b>ROOMS & RATES</b></h2>
        <p className="text-center text-muted mb-5">
          Semper ac dolor vitae accumsan. Cras interdum hendrerit lacinia. Phasellus accumsan urna vitae molestie interdum. Nam sed placerat libero, non eleifend dolor.
        </p>
        <div className="row">
          {roomData.map(room => (
            <RoomItem key={room.id} {...room} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default RoomsAndRates;