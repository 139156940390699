import React, { useState, useEffect } from 'react';
import Isotope from 'isotope-layout';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import images (same as before)
import img1 from '../../assets/images/gallery/img-1.jpg';
import room1 from '../../assets/images/realImages/room1.jpg'
import room2 from '../../assets/images/realImages/room2.jpg'
import room3 from '../../assets/images/realImages/room3.jpg'
import room4 from '../../assets/images/realImages/room4.jpg'
import room5 from '../../assets/images/realImages/room5.jpg'
import room7 from '../../assets/images/realImages/room7.jpg'
import room8 from '../../assets/images/realImages/room8.jpg'
import view1 from '../../assets/images/realImages/view1.jpg'
import view2 from '../../assets/images/realImages/view2.jpg'
import view3 from '../../assets/images/realImages/view3.jpg'
import view4 from '../../assets/images/realImages/view4.jpg'
import view5 from '../../assets/images/realImages/view5.jpg'

const GalleryComponent = () => {
  const [isotope, setIsotope] = useState(null);
  const [filterKey, setFilterKey] = useState('*');

  useEffect(() => {
    setIsotope(
      new Isotope('.gallery-isotope', {
        itemSelector: '.item-isotope',
        layoutMode: 'fitRows',
      })
    );
  }, []);

  useEffect(() => {
    if (isotope) {
      filterKey === '*'
        ? isotope.arrange({ filter: `*` })
        : isotope.arrange({ filter: `.${filterKey}` });
    }
  }, [isotope, filterKey]);

  const handleFilterKeyChange = key => () => setFilterKey(key);

  const galleryItems = [
    { id: 1, categories: ['suite', 'dining'], image: view1 },
    { id: 2, categories: ['ground', 'bathroom', 'suite'], image: room8 },
    { id: 3, categories: ['ground', 'bathroom', 'dining'], image: room7 },
    { id: 4, categories: ['suite', 'dining'], image: view2 },
    { id: 5, categories: ['ground', 'suite', 'dining'], image: room1 },
    { id: 6, categories: ['ground', 'bathroom', 'dining'], image: room2 },
    { id: 7, categories: ['ground', 'suite', 'dining'], image: room3 },
    { id: 8, categories: ['bathroom', 'suite', 'dining'], image: room4 },
    { id: 9, categories: ['bathroom', 'suite', 'dining'], image: room5 },
    { id: 10, categories: ['ground', 'bathroom'], image: view3 },
    { id: 11, categories: ['ground', 'bathroom', 'suite'], image: view4 },
    { id: 12, categories: ['ground', 'bathroom'], image: view5 },
  ];

  const filters = [
    { key: '*', label: 'ALL' },
    { key: 'ground', label: 'HOTEL & GROUND' },
    { key: 'suite', label: 'ROOM/SUITE' },
  ];

  return (
    <section className="section-gallery bg-white" id="gallery">
      <div className="container-fluid px-0">
        <h2 className="text-center mb-4" style={{ fontWeight: 'bold' }}>GALLERY</h2>
        
        <div className="gallery-cat">
          <ul className="nav justify-content-center" style={{ borderBottom: '1px solid #e9ecef' }}>
            {filters.map((filter) => (
              <li className="nav-item" key={filter.key}>
                <a
                  className={`nav-link ${filterKey === filter.key ? 'active' : ''}`}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleFilterKeyChange(filter.key)();
                  }}
                  style={{
                    color: filterKey === filter.key ? '#000' : '#6c757d',
                    backgroundColor: filterKey === filter.key ? '#e9ecef' : 'transparent',
                    borderRadius: '0',
                    padding: '10px 15px',
                  }}
                >
                  {filter.label}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="gallery-content mt-4">
          <div className="gallery-isotope row g-0">
            {galleryItems.map((item) => (
              <div key={item.id} className={`col-md-2 item-isotope ${item.categories.join(' ')}`}>
                <div className="gallery_item" style={{ position: 'relative', paddingBottom: '100%', overflow: 'hidden' }}>
                  <img 
                    src={item.image} 
                    alt={`Gallery item ${item.id}`} 
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GalleryComponent;