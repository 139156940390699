import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h2>Privacy Policy</h2>
      <p>At Sri Ram International Home Stay, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information.</p>

      <h3>1. Information We Collect</h3>
      <p>
        <strong>Personal Information:</strong> We collect personal details such as your name, email address, phone number, payment information, and booking details when you book with us.
      </p>
      <p>
        <strong>Usage Data:</strong> We collect data on how you use our website, including your IP address, browser type, and other analytics data to improve our services.
      </p>

      <h3>2. How We Use Your Information</h3>
      <p>We use the information you provide to:</p>
      <ul>
        <li>Process bookings and payments.</li>
        <li>Communicate with you regarding your booking or inquiries.</li>
        <li>Send promotional offers or newsletters (you can opt-out at any time).</li>
        <li>Improve our website and services.</li>
      </ul>

      <h3>3. Data Sharing</h3>
      <p>
        We do not sell or rent your personal information to third parties. We may share your information with trusted service providers (e.g., payment processors) as necessary to fulfill your booking. We may also disclose your information if required by law or to protect our legal rights.
      </p>

      <h3>4. Security</h3>
      <p>
        We use industry-standard security measures to protect your personal information. However, no online transmission is entirely secure, so please take appropriate precautions when using our services.
      </p>

      <h3>5. Cookies</h3>
      <p>
        Our website uses cookies to enhance your browsing experience. You can disable cookies through your browser settings, but this may affect certain functionalities of the website.
      </p>

      <h3>6. Your Rights</h3>
      <p>You have the right to access, correct, or delete your personal data. For any privacy-related concerns, please contact us at [email].</p>

      <h3>7. Changes to Policy</h3>
      <p>We may update this Privacy Policy periodically. Please review it regularly to stay informed on how we protect your information.</p>
    </div>
  );
};

export default PrivacyPolicy;
