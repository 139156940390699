import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img5 from '../../assets/images/realImages/rammadirayodhyawallpaper.png';
import hom1slide1 from '../../assets/images/slider/hom1-slide1.png'
import img4 from '../../assets/images/slider/img-4.jpg';
import icoSlide1 from '../../assets/images/icon-slider-1.png'
import icoSlide2 from '../../assets/images/icon-slider-2.png'
import logoFooter from '../../assets/images/logo_header4.png'

const Banner = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: true,
        fade: true,
        pauseOnHover: true,
      };

    return (
        <section className="section-slider">
            <h1 className="element-invisible">Slider</h1>
            <Slider {...settings}>
                {/* Slide 1 */}
                <div style={{ position: 'relative' }}>
                    <img
                        src={img5}
                        alt="Slide 1"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <div className="slider-caption" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', color: '#fff', zIndex: 1 }}>
                        <div className="tp-caption" style={{ marginBottom: '15px' }}>
                            <img src={logoFooter} alt="icons" style={{ margin: 'auto', width: '150px' }} />
                        </div>
                        <div className="tp-caption" style={{ display: "block", fontSize: '20px', color: '#fff', textTransform: 'uppercase', letterSpacing: '1px', marginBottom: '10px' }}>
                            Welcome to
                        </div>
                        <div className="tp-caption" style={{ fontSize: '44px', fontWeight: '700', color: '#fff', marginBottom: '20px' }}>
                        Shri Ram International Home Stay
                        </div>
                        <a  className="awe-btn" style={{ padding: '12px 30px', backgroundColor: '#C87137', color: '#fff', textDecoration: 'none', fontWeight: '600', textTransform: 'uppercase', letterSpacing: '1px' }}>
                           Book Now
                        </a>
                    </div>
                </div>

                {/* Slide 2 */}
                <div style={{ position: 'relative' }}>
                    <img
                        src={img4}
                        alt="Slide 2"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <div className="slider-caption" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', color: '#fff', zIndex: 1 }}>
                        <div className="tp-caption" style={{ marginBottom: '15px' }}>
                            <img src={icoSlide1} alt="icon" style={{ margin: 'auto' }} />
                        </div>
                        <div className="tp-caption" style={{ display: "block", fontSize: '20px', color: '#fff', textTransform: 'uppercase', letterSpacing: '1px', marginBottom: '10px' }}>
                            Each Hotel is
                        </div>
                        <div className="tp-caption" style={{ fontSize: '60px', fontWeight: '700', color: '#fff', marginBottom: '10px' }}>
                            Unique 60%
                        </div>
                        <div className="tp-caption" style={{ fontSize: '20px', color: '#fff', textTransform: 'uppercase', letterSpacing: '1px', marginBottom: '20px' }}>
                            Just Like You
                        </div>
                        <div className="tp-caption">
                            <img src={icoSlide2} alt="icon" style={{ margin: 'auto' }} />
                        </div>
                    </div>
                </div>
            </Slider>
        </section>
    )
}

export default Banner