import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Room from './pages/Room';
import Checkout from './pages/Checkout';
import ConfirmationPage from './pages/Confirmation';
import PreviousBookingsPage from './pages/PreviousBookings';
import TermsAndCondition from './components/TermsAndCondition';
import PrivacyPolicy from './components/PrivacyPolicy';
import CancellationRefundPolicy from './components/CancellationAndRefund';
import ShippingDeliveryPolicy from './components/ShippingAndDelivery';

function App() {
  return (
    <Router>
      <div className="App" id="page-wrap">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/room" element={<Room />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path='/confirmation' element={<ConfirmationPage />} />
          <Route path='/bookings' element={<PreviousBookingsPage />} />
          <Route path="/terms" element={TermsAndCondition} />
          <Route path="/privacy" element={PrivacyPolicy} />
          <Route path="/cancellation-refund" element={CancellationRefundPolicy} />
          <Route path="/shipping-delivery" element={ShippingDeliveryPolicy} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;