import React from 'react';
import { FaPinterest, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { MdLocationOn, MdPhone } from 'react-icons/md';
import logoFooter from '../../assets/images/logo_header4.png'

const Footer = () => {
  return (
    <footer className="bg-dark text-light py-5" id="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 mb-4">
            <div className="d-flex align-items-center mb-3">
              <img src={logoFooter} alt="Shri Ram Logo" className="me-3" style={{ maxHeight: '50px' }} />
              <h3 className="h4 mb-0">Shri Ram International</h3>
            </div>
            <p className="d-flex align-items-center mb-2">
              <MdLocationOn className="me-2" />
              Ronak Puram Colony, Faizabad, Uttar Pradesh 224133
            </p>
            <p className="d-flex align-items-center">
              <MdPhone className="me-2" />
              +91 8840766401, +91 9616152322
            </p>
          </div>


          <div className="col-lg-6 mb-4">
            <div className="mailchimp-form">
              <form action="#" method="POST">
                <input type="text" name="email" placeholder="Your email address" className="input-text" style={{width: "50%"}} />
                <button className="awe-btn">SIGN UP</button>
              </form>
            </div>
          </div>
        </div>

        <hr className="my-4" />

        <div className="row">
          <div className="col-md-8">
            <p className="mb-0">&copy; 2024 Shri Ram International Home Stay All rights reserved.</p>
          </div>
          <div className="col-md-4">
            <ul className="list-inline text-md-end mb-0">
              <li className="list-inline-item"><a href="#" className="text-light"><FaFacebook /></a></li>
              <li className="list-inline-item"><a href="#" className="text-light"><FaTwitter /></a></li>
              <li className="list-inline-item"><a href="#" className="text-light"><FaInstagram /></a></li>
              <li className="list-inline-item"><a href="#" className="text-light"><FaPinterest /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;