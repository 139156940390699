import React, { useState } from 'react';
import { Carousel, Form, Button } from 'react-bootstrap';
import { Calendar } from 'lucide-react';
import './RoomDetail.css';
import { Link } from 'react-router-dom';

import logoIcon from '../../assets/images/logo_header3.png';
import roomImg1 from '../../assets/images/realImages/room1.jpg';
import roomImg2 from '../../assets/images/realImages/room2.jpg';
import roomImg3 from '../../assets/images/realImages/room3.jpg';
import roomImg4 from '../../assets/images/realImages/room4.jpg';
import roomImg5 from '../../assets/images/realImages/room5.jpg';
import roomImg6 from '../../assets/images/realImages/room6.jpg';
import roomImg7 from '../../assets/images/realImages/room7.jpg';

const RoomDetail = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const images = [roomImg1, roomImg2, roomImg3, roomImg4, roomImg5, roomImg6, roomImg7];

  return (
    <>
      <section class="section-sub-banner bg-16">
        <div class="awe-overlay"></div>
        <div class="sub-banner">
          <div class="container">
            <div class="text text-center">
              <h2>AC ROOM</h2>
            </div>
          </div>

        </div>

      </section>
      <div className="room-detail-container">
        <div className="room-detail-content">
          <div className="carousel-container">
            <Carousel activeIndex={index} onSelect={handleSelect} indicators={false}>
              {images.map((image, idx) => (
                <Carousel.Item key={idx}>
                  <img className="d-block w-100" src={image} alt={`Room view ${idx + 1}`} />
                </Carousel.Item>
              ))}
            </Carousel>
            <div className="thumbnail-container">
              {images.map((image, idx) => (
                <img
                  key={idx}
                  src={image}
                  alt={`Thumbnail ${idx + 1}`}
                  className={`thumbnail ${idx === index ? 'active' : ''}`}
                  onClick={() => setIndex(idx)}
                />
              ))}
            </div>
          </div>
          <div className="booking-form">
            <img src={logoIcon} alt="Logo" className="logo" />
            <h6 className="starting-from">STARTING ROOM FROM</h6>
            <p className="price">₹ 1,500 <span className="per-day">+ taxes per night for 1 room</span></p>

            <Form>
              <Form.Group >
                <Form.Label>ARRIVE</Form.Label>
                <div className="input-with-icon">
                  <Calendar size={20} />
                  <Form.Control type="text" placeholder="Arrive Date" />
                </div>
              </Form.Group>
              <Form.Group  style={{marginTop: "20px"}}>
                <Form.Label>DEPATURE</Form.Label>
                <div className="input-with-icon">
                  <Calendar size={20} />
                  <Form.Control type="text"  placeholder="Departure Date" />
                </div>
              </Form.Group>
              <Form.Group  style={{marginTop: "20px"}}>
                <Form.Label>ADULT</Form.Label>
                <Form.Control type="number" min={1} defaultValue="3">
                </Form.Control>
              </Form.Group>
              <Form.Group style={{marginTop: "20px"}}>
                <div>
                    CHILDREN
                </div>
                <div style={{color: "#808080"}}>0 to 5 years old</div>
                <Form.Control type="number" min={0} defaultValue="3">
                </Form.Control>
              </Form.Group>
              <Link to="/checkout">
                <Button className="submit-btn" type="submit" block>PROCEED</Button>
              </Link>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomDetail;