import React, {useEffect} from "react";
import Banner from '../../components/Banner'
import RoomsAndRates from '../../components/RoomsAndRates'
import AboutUs from "../../components/AboutUs";
import OurBest from "../../components/OurBest";
import TestimonialSlider from '../../components/Testimonials'
import EventNewsSection from '../../components/EventNewsSection'
import GalleryComponent from '../../components/Gallery'
import Footer from '../../components/Footer';
const Home = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  useEffect(() => {
    scrollToTop()
  }, [])
  return (
    <>
      <Banner />
      <RoomsAndRates />
      <AboutUs />
      <OurBest />
      <TestimonialSlider />
      <GalleryComponent />
    </>
  );
}

export default Home;
