import React from 'react';

const CancellationRefundPolicy = () => {
  return (
    <div className="cancellation-refund-policy">
      <h2>Cancellation and Refund Policy</h2>
      <p>
        At Sri Ram International Home Stay, we understand that plans can change. Our cancellation and refund policy is designed to provide flexibility while protecting our business.
      </p>

      <h3>1. Cancellation Timeframes</h3>
      <p>
        <strong>Full Refund:</strong> If you cancel your booking [X] days before the check-in date, you will receive a full refund minus any processing fees.
      </p>
      <p>
        <strong>Partial Refund:</strong> Cancellations made between [X-Y] days before the check-in date will receive a [percentage] refund.
      </p>
      <p>
        <strong>No Refund:</strong> Cancellations made within [Y] days of the check-in date are non-refundable.
      </p>

      <h3>2. Refund Processing</h3>
      <p>
        Refunds will be processed within [number of days] after cancellation and will be credited to the original method of payment.
      </p>

      <h3>3. Early Departure</h3>
      <p>No refunds will be issued for early departures or unused nights during your stay.</p>

      <h3>4. Force Majeure</h3>
      <p>
        In the event of unforeseen circumstances such as natural disasters, pandemics, or government restrictions, we may offer a full refund or the option to reschedule without additional charges.
      </p>
    </div>
  );
};

export default CancellationRefundPolicy;
