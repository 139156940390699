import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Check, Printer, Calendar, Users, Home } from 'lucide-react';
import { Link } from 'react-router-dom';
import './Confirmation.css';

const ConfirmationPage = () => {
  const bookingDetails = {
    bookingId: 'BOOK123456',
    checkIn: '2023-10-15',
    checkOut: '2023-10-17',
    roomType: 'Deluxe Double',
    guests: 2,
    totalAmount: 2912,
  };

  return (
    <div className="confirmation-page">
      {/* Hero Section */}
      <section className="section-sub-banner bg-9">
        <div className="awe-overlay"></div>
        <div className="sub-banner">
          <div className="container">
            <div className="text text-center">
              <h2>Confirmation</h2>
            </div>
          </div>
        </div>
      </section>

      {/* Confirmation Card */}
      <Container className="mt-n5 margin-20">
        <Row className="justify-content-center">
          <Col md={10} lg={8}>
            <Card className="shadow">
              <Card.Body className="p-5">
                <div className="text-center mb-4">
                  <div className="bg-success bg-opacity-10 d-inline-block rounded-circle p-3 mb-3">
                    <Check className="text-success" size={40} />
                  </div>
                  <h2 className="h3 mb-4">Booking Details</h2>
                </div>

                {/* Booking Info Grid */}
                <Row className="g-4 mb-5">
                  <Col md={6}>
                    <div className="d-flex align-items-center">
                      <Calendar className="text-primary me-3" size={24} />
                      <div>
                        <small className="text-muted d-block">Check-in</small>
                        <strong>{bookingDetails.checkIn}</strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex align-items-center">
                      <Calendar className="text-primary me-3" size={24} />
                      <div>
                        <small className="text-muted d-block">Check-out</small>
                        <strong>{bookingDetails.checkOut}</strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex align-items-center">
                      <Home className="text-primary me-3" size={24} />
                      <div>
                        <small className="text-muted d-block">Room Type</small>
                        <strong>{bookingDetails.roomType}</strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex align-items-center">
                      <Users className="text-primary me-3" size={24} />
                      <div>
                        <small className="text-muted d-block">Guests</small>
                        <strong>{bookingDetails.guests}</strong>
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* Booking ID and Total */}
                <Card className="bg-light mb-5">
                  <Card.Body>
                    <Row className="align-items-center mb-2">
                      <Col>
                        <span className="text-muted">Booking ID</span>
                      </Col>
                      <Col className="text-end">
                        <strong>{bookingDetails.bookingId}</strong>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col>
                        <span className="text-muted">Total Amount</span>
                      </Col>
                      <Col className="text-end">
                        <strong className="h4">₹{bookingDetails.totalAmount}</strong>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <div className="d-flex flex-column flex-sm-row justify-content-center gap-3">
                  <Link to="/" >
                    <button className="awe-btn">Back to Home</button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ConfirmationPage;