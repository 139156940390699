import React, {useEffect} from "react";
import LoginComponent from "../../components/Login";
const Login = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
 
  useEffect(() => {
    scrollToTop()
  }, [])
  return (
    <>
      <LoginComponent> </LoginComponent>
    </>
  );
}

export default Login;
