import React from 'react';
import aboutImage from '../../assets/images/realImages/view6.jpg'
const AboutUs = () => {
  return (
    <section className="section-home-about bg-white" id="about">
      <div className="container">
        <div className="home-about">
          <div className="row">
            <div className="col-md-6">
              <div className="img">
                <a href="#"><img src={aboutImage} alt="About Us" /></a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text">
                <h2 className="heading">ABOUT US</h2>
                <span className="box-border"></span>
                <p>
                Shri Ram International Home Stay is more than just accommodation. it's a gateway to authentic Indian culture and hospitality. We started as a modest guesthouse has blossomed into a beloved destination for travelers seeking a genuine Indian experience.
                At Shri Ram International Home Stay, we believe in the age-old Indian philosophy of "Atithi Devo Bhava" - meaning "The Guest is God." This principle guides every aspect of our service, from the warm welcome you receive upon arrival to the fond farewell when you depart.
                Our homestay is nestled in a tranquil neighborhood, offering a peaceful retreat from the bustling city life while still being conveniently located near major attractions. The property itself is a beautiful blend of traditional architecture and modern comforts.
                We take pride in our commitment to sustainability and supporting the local community. Our home-cooked meals are prepared using locally-sourced, organic ingredients whenever possible, and we actively participate in community initiatives to preserve our cultural heritage and environment.
                Whether you're a solo traveler looking to immerse yourself in Indian culture, a couple seeking a romantic getaway, or a family wanting to create lasting memories, Shri Ram International Home Stay offers a personalized experience tailored to your needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;