import React from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import './Checkout.css';
import roomImg1 from '../../assets/images/realImages/room1.jpg';
import { Info } from 'lucide-react';
import { Link } from 'react-router-dom';

const CheckoutSection = () => {
  return (
    <>
      <section className="section-sub-banner bg-9">
        <div className="awe-overlay"></div>
        <div className="sub-banner">
          <div className="container">
            <div className="text text-center">
              <h2>CheckOut</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="section-checkout">
        <Container>
          <div className="checkout">
            <Row>
              <Col md={6}>
                <div className="checkout_head">
                  <h3>BILLING DETAILS</h3>
                </div>
                <Form className="checkout_form">
                  <Row>
                    <Col xs={6}>
                      <Form.Group>
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control type="text" />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group>
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control type="text" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Group style={{ marginTop: '20px' }}>
                      <Form.Label>Address </Form.Label>
                      <Form.Control as="textarea" placeholder="Address" />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Col xs={6}>
                      <Form.Group>
                        <Form.Label>Email Address*</Form.Label>
                        <Form.Control type="email" />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group>
                        <Form.Label>Phone*</Form.Label>
                        <Form.Control type="tel" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group style={{ marginTop: '20px' }}>
                    <Form.Label>Notes</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Notes about your order, eg. special notes for delivery" />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6}>
                <div className="checkout_head">
                  <h3>YOUR PAYMENT DETAILS</h3>
                </div>
                <Card className="checkout_cart price-card">
                  <Card.Body>
                    <h5 className="mb-3 price-title"><strong>Price Breakup</strong></h5>
                    <div className="d-flex justify-content-between mb-2 price-item">
                      <span>2 Beds x 2 Nights</span>
                      <span className="price-amount">₹ 2,600</span>
                    </div>
                    <div className="d-flex justify-content-between mb-2 price-item">
                      <span>Base Price</span>
                      <span className="price-amount color-red">₹ 2,600</span>
                    </div>
                    <div className="d-flex justify-content-between mb-2 price-item">
                      <span>Hotel Taxes <Info size={18} className="info-icon" /></span>
                      <span className="price-amount">₹ 312</span>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between price-total">
                      <strong>Total Amount to be paid</strong>
                      <strong className="price-amount">₹ 2,912</strong>
                    </div>
                  </Card.Body>
                </Card>
                <div className="checkout_option mt-4">
                  <Form.Group>
                    <Form.Check
                      type="radio"
                      name="paymentMethod"
                      id="bankTransfer"
                      label="Direct Bank Transfer"
                    />
                    <p className="payment-description">Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won't be shipped until the funds have cleared in our account.</p>
                    <Form.Check
                      type="radio"
                      name="paymentMethod"
                      id="cheque"
                      label="Cheque Payment"
                    />
                    <Form.Check
                      type="radio"
                      name="paymentMethod"
                      id="creditCard"
                      label="Credit Card"
                    />
                  </Form.Group>
                </div>
                <div className="checkout_btn">
                  <Link to="/confirmation">
                    <Button className="submit-btn" style={{ width: '100%' }}>Pay Now</Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default CheckoutSection;