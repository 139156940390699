import React, {useEffect} from "react";
import RegisterComponent from "../../components/Register";
const Register = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  useEffect(() => {
    scrollToTop()
  }, [])
  return (
    <>
      <RegisterComponent />
    </>
  );
}

export default Register;
