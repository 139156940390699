import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/helper.css'
import './assets/css/custom.css'
import './assets/css/responsive.css'
import './assets/css/style.css'
import './assets/css/lib/font-lotusicon.css'
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
