import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logoHeader from '../../assets/images/logo_header.png'
import { MdLocationOn, MdPhone } from 'react-icons/md';

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const headerContentRef = useRef(null);
  const headerTopRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const windowScroll = window.pageYOffset;
      const topHeight = headerTopRef.current?.clientHeight || 0;

      setIsSticky(windowScroll > topHeight);
    };

    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const sizePoint = headerContentRef.current?.dataset.responsive || 1199;

      setIsMobile(windowWidth <= sizePoint);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Initial calls
    handleScroll();
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  const toggleSubmenu = (event) => {
    event.preventDefault();
    const parentLi = event.target.closest('li');
    const submenu = parentLi.querySelector('ul');

    if (submenu) {
      submenu.style.display = submenu.style.display === 'none' ? 'block' : 'none';
    }
  };

  const scrollToAbout = (event) => {
    event.preventDefault();
    const aboutSection = document.getElementById('about');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToGallery = (event) => {
    event.preventDefault();
    const gallerySection = document.getElementById('gallery');
    if (gallerySection) {
      gallerySection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header id="header">
      <div ref={headerTopRef} className="header_top">
        <div className="container">
          <div className="header_left float-left">
            <span><MdLocationOn /> Ronak Puram Colony, Faizabad, Uttar Pradesh 224133</span>
            <span><MdPhone />  +91 8840766401, +91 9616152322</span>
          </div>
          <div className="header_right float-right">
            <span className="login-register">
              <Link to="/login">Login</Link>
              <Link to="/register">register</Link>
            </span>
          </div>
        </div>
      </div>
      <div
        ref={headerContentRef}
        id="header_content"
        className={`header_content ${isSticky ? 'header-sticky' : ''} ${isMobile ? 'header_mobile' : ''}`}
        data-responsive="1199"
      >
        <div className="container">
          <div className="header_logo">
            <Link to="/"><img src={logoHeader} alt="" /></Link>
          </div>
          <nav className={`header_menu ${isMenuActive ? 'active' : ''}`}>
            <ul className="menu">
              <li className="">
                <Link to="/">Home </Link>
              </li>
              <li>
                <Link to="/">
                  <a href="#about" onClick={scrollToAbout}>About</a>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <a href="#gallery" onClick={scrollToGallery}>Gallery </a>
                </Link>
              </li>
            </ul>
          </nav>
          <span className={`menu-bars ${isMenuActive ? 'active' : ''}`} onClick={toggleMenu}>
            <span></span>
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;