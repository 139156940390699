import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap CSS

// Import the actual images as in the original code
import avatarImg from '../../assets/images/avatar/img-5.jpg';
import backgroundImg from '../../assets/images/realImages/nightlights.png';

const TestimonialSlider = () => {
  const testimonials = [
    {
      id: 1,
      image: avatarImg,
      text: "This is the only place to stay in Catalina! I have stayed in the cheaper hotels and they were fine, but this is just the icing on the cake! After spending the day bike riding and hiking to come back and enjoy a glass of wine while looking out your ocean view window and then to top it all off...",
      name: "Seelentag",
      location: "From Los Angeles, California"
    },
    // Add more testimonials here if needed
  ];

  return (
    <div className="bg-image py-5" style={{backgroundImage: `url(${backgroundImg})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <Carousel
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={5000}
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                return (
                  <button
                    className={`btn btn-sm mx-1 rounded-circle ${isSelected ? 'btn-light' : 'btn-outline-light'}`}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    key={index}
                    title={`${label} ${index + 1}`}
                    style={{ width: '10px', height: '10px', padding: 0 }}
                  />
                );
              }}
            >
              {testimonials.map((testimonial) => (
                <div key={testimonial.id} className="text-center px-4">
                  <div className="d-flex flex-column align-items-center">
                    <div className="mb-4">
                      <img 
                        src={testimonial.image} 
                        alt={testimonial.name} 
                        className="rounded-circle border border-4 border-white"
                        style={{ width: '80px', height: '80px', objectFit: 'cover', maxWidth: '80px', maxHeight: '80px' }}
                      />
                    </div>
                    <p className="lead mb-4 fst-italic text-white">"{testimonial.text}"</p>
                    <p className="fw-bold text-white mb-1">{testimonial.name}</p>
                    <p className="text-white-50 small">{testimonial.location}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;