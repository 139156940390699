import React, { useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const RegisterComponent = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Registration attempted with:', { username, email, password });
  };

  return (
    <section className="section-account parallax bg-11">
      <div className="awe-overlay"></div>
      <div className="container">
        <div className="login-register">
          <div className="text text-center">
            <h2>REGISTER FORM</h2>
            <form onSubmit={handleSubmit} className="account_form">
              <div className="field-form">
                <input 
                  type="text" 
                  className="field-text" 
                  placeholder="User name*"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="field-form">
                <input 
                  type="email" 
                  className="field-text" 
                  placeholder="Email*"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="field-form">
                <input 
                  type={showPassword ? "text" : "password"} 
                  className="field-text" 
                  placeholder="Password*"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span 
                  className="view-pass" 
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                </span>
              </div>
              <div className="field-form field-submit">
                <button type="submit" className="awe-btn awe-btn-13">REGISTER</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterComponent;