import React from 'react';
import icon1 from '../../assets/images/home/ourbest/icon-1.png';
import icon2 from '../../assets/images/home/ourbest/icon-2.png';
import icon3 from '../../assets/images/home/ourbest/icon-3.png';
import icon4 from '../../assets/images/home/ourbest/icon-4.png';
import icon5 from '../../assets/images/home/ourbest/icon-5.png';
import ourBestImage from '../../assets/images/realImages/rom8.jpg';

const OurBest = () => {
  const amenities = [
    { icon: icon3, text: "Comfortable Traditional Rooms" },
    { icon: icon2, text: "Complimentary Masala Chai" },
    { icon: icon4, text: "Air-Conditioned Rooms" },
    { icon: icon5, text: "Free High-Speed Wi-Fi" },
    { icon: icon1, text: "Home-Cooked Indian Meals" },
  ];

  return (
    <section className="section-our-best bg-white">
      <div className="container">
        <div className="our-best">
          <div className="row">
         
            <div className="col-md-6 col-md-push-6">
              <div className="text">
                <h2 className="heading">Our Best</h2>
                <p>
                One of Shri Ram International Home Stay's most cherished accommodations, this family-run retreat is renowned as a leading homestay in Ayodhya, offering warm Indian hospitality, thoughtful amenities, and a distinctive cultural experience.
                </p>
                <ul>
                  {amenities.map((item, index) => (
                    <li key={index}>
                      <img src={item.icon} alt="icon" />
                      {item.text}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-md-pull-6">
            <div className="img">
              <img src={ourBestImage} alt="Our Best" />
            </div>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurBest;