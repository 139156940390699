import React, { useEffect } from "react";
import CheckoutSection from "../../components/CheckoutSection";
const Checkout = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <>
        <CheckoutSection />
    </>
  );
}
  
export default Checkout;
