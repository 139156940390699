import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <h2>Terms and Conditions</h2>
      <p>Welcome to Sri Ram International Home Stay. By booking with us, you agree to the following Terms and Conditions. Please read them carefully.</p>

      <h3>1. Booking and Payment</h3>
      <p>
        All bookings must be made through our website or an authorized platform. A deposit of [percentage] is required at the time of booking to secure your reservation. The remaining balance must be paid [time frame] before arrival.
        Prices are subject to change without prior notice. However, once a booking is confirmed, the price is locked.
      </p>

      <h3>2. Check-in and Check-out</h3>
      <p>
        Check-in time is [check-in time], and check-out time is [check-out time]. Early check-in or late check-out is subject to availability and may incur additional charges.
      </p>

      <h3>3. Guest Responsibilities</h3>
      <p>
        Guests are responsible for any damage caused during their stay and will be charged accordingly. Please respect the property and the neighborhood. Any behavior that disturbs other guests or residents may result in termination of your stay without a refund.
      </p>

      <h3>4. Cancellation Policy</h3>
      <p>
        Cancellations made [X] days prior to the check-in date will receive a [percentage] refund. Refer to our <strong>Cancellation and Refund Policy</strong> for more details.
      </p>

      <h3>5. Pets</h3>
      <p>[Insert pet policy here – if pets are allowed or not, and any applicable fees].</p>

      <h3>6. Liability</h3>
      <p>
        Sri Ram International Home Stay is not liable for any loss, theft, or damage to personal belongings. Guests should take care of their valuables.
      </p>

      <h3>7. Changes to Terms</h3>
      <p>We reserve the right to change these Terms and Conditions at any time. Please check our website for the latest updates.</p>

      <h3>8. Governing Law</h3>
      <p>
        These terms are governed by the laws of [Jurisdiction/State], and any disputes will be resolved in the courts of [Location].
      </p>
    </div>
  );
};

export default TermsAndConditions;
