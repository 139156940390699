import React from 'react';

const ShippingDeliveryPolicy = () => {
  return (
    <div className="shipping-delivery-policy">
      <h2>Shipping and Delivery Policy</h2>
      <p>
        This policy applies to any physical goods purchased from Sri Ram International Home Stay (e.g., gift vouchers, souvenirs).
      </p>

      <h3>1. Order Processing</h3>
      <p>All orders are processed within [number of days] of purchase. Orders placed on weekends or holidays will be processed the next business day.</p>

      <h3>2. Shipping Methods</h3>
      <p>We offer the following shipping methods: [Insert shipping methods]. Shipping costs will be calculated at checkout.</p>

      <h3>3. Delivery Time</h3>
      <p>Delivery times typically range between [number of days], depending on your location.</p>

      <h3>4. Non-Delivery or Late Delivery</h3>
      <p>
        If your order has not arrived within the estimated delivery time, please contact us at [email] for assistance.
      </p>

      <h3>5. Damaged Goods</h3>
      <p>
        If your item arrives damaged, please contact us within [number of days] with photographic evidence, and we will arrange for a replacement or refund.
      </p>
    </div>
  );
};

export default ShippingDeliveryPolicy;
