import React, {useEffect} from "react";
import RoomDetail from "../../components/RoomDetail";
const Room = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  useEffect(() => {
    scrollToTop()
  }, [])
  return (
    <>
        <RoomDetail />
    </>
  );
}
  
export default Room;
